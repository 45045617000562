import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Resume from '../documents/resume(2019-02-26).pdf'

const AboutPageLinksData = [
	{ label: 'Bēhance', link: 'https://www.behance.net/_aaronvince' },
	{ label: 'Instagram', link: 'https://www.instagram.com/_aaronvince' },
	{ label: 'Twitter', link: 'https://twitter.com/_aaronvince' },
	{ label: 'LinkedIn', link: 'https://www.linkedin.com/in/aaronvince' },
	{ label: 'Resumé', link: Resume },
	{ label: 'Email', link: 'mailto:aaronvince@me.com' }
]

const AboutTextContainer = styled.section`
	/* min-height: 50vh; */
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	@media (min-width: 576px) {
	}

	@media (min-width: 768px) {
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
	}
`

const HeadingOne = styled.section`
	font-weight: normal;
	font-size: 36px;
	color: var(--primary-colour);
	margin: 48px 0;

	@media (min-width: 576px) {
	}

	@media (min-width: 768px) {
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
	}
`

const DescriptionParagraph = styled.p`
	font-size: 16px;
	line-height: 1.333;
	max-width: 500px;

	font-weight: normal;
	/* color: var(--primary-text-colour); */
	/* margin-bottom: 24px; */

	&:nth-child(n + 3) {
		text-indent: 20px;
	}

	@media (min-width: 576px) {
	}

	@media (min-width: 768px) {
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
	}
`

const AnchorTag = styled.a`
	@media (min-width: 576px) {
	}

	@media (min-width: 768px) {
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
	}
`

const AboutPageLinks = styled.ul`
	max-width: 80%;
	font-size: 12pt;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 1rem;
	margin-bottom: 12px;

	& > li {
		display: inline;
		margin-bottom: 12px;
		/* margin-left: 0.5rem;
		margin-right: 0.5rem; */
	}

	& > div {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}

	:first-child {
		margin-left: 0;
	}

	:last-child {
		margin-right: 0;
	}

	@media (min-width: 576px) {
	}

	@media (min-width: 768px) {
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
	}
`

const ImageDescriptionParagraph = styled(DescriptionParagraph)`
	text-indent: 0 !important;

	margin-top: 12px;
`

const AboutPage = ({ data }) => (
	<Layout>
		<SEO title="About Page for Aaron Vince" />
		<AboutTextContainer>
			<HeadingOne>Hi!</HeadingOne>
			<DescriptionParagraph>
				Currently, I’m in my third year of studying design at the{' '}
				<AnchorTag href="https://design.ampd.yorku.ca">
					York/Sheridan Program in Design
				</AnchorTag>{' '}
				<span role="img" aria-label="emoji of a student">
					👨‍🏫
				</span>
				. As a designer, I’m enthusiastic about building brands and designing
				impactful experiences. I like to start every project with thorough
				research and analysis 🧮 to help make informed future design decisions.
				From there, I use branding, UX principles, front-end development, or
				graphic design to translate information into emotion.
			</DescriptionParagraph>
			<DescriptionParagraph>
				In my free time, I enjoy walking my puggle,{' '}
				<AnchorTag href="https://www.instagram.com/snugglespup">
					Buddy
				</AnchorTag>{' '}
				<span role="img" aria-label="emoji of a dog">
					🐶
				</span>
				, in the park, making new vegetarian recipes, listening to podcasts 📱
				like Good Job, Brain!, The Flop House, and FiveThirtyEight Politics, or
				watching stand-up 🎤 like Gina Yashere, Beth Stelling, or Bill Burr.{' '}
			</DescriptionParagraph>
			<DescriptionParagraph>
				I’m also passionate about ongoing learning by maintaining good habits
				over time, with the help of the getting things done app, Things.
				Currently, I’m improving on my German{' '}
				<span role="img" aria-label="emoji of a german flag">
					🇩🇪
				</span>{' '}
				with{' '}
				<AnchorTag href="https://www.duolingo.com/Uvitra">Duolingo</AnchorTag>{' '}
				and learning SVG, 3D, and React on Lynda.com.{' '}
			</DescriptionParagraph>
			<DescriptionParagraph>
				Feel like working together to make something great? Let’s grab a coffee{' '}
				<span role="img" aria-label="emoji of a cup of coffee">
					☕️
				</span>{' '}
				some time! If you need to get in touch or learn more about what I do,
				find me on:
			</DescriptionParagraph>
			<AboutPageLinks>
				{AboutPageLinksData.map((item, index) => (
					<React.Fragment key={index}>
						<li>
							<AnchorTag href={item.link}>{item.label}</AnchorTag>
						</li>
						{index !== AboutPageLinksData.length - 1 ? <div>∙</div> : null}
					</React.Fragment>
				))}
			</AboutPageLinks>
		</AboutTextContainer>
		<Img fluid={data.aboutImage.childImageSharp.fluid} />
		<ImageDescriptionParagraph>
			My partner and me in the clouds.
		</ImageDescriptionParagraph>
	</Layout>
)

export default AboutPage

AboutPage.propTypes = {
	data: PropTypes.object
}

export const pageQuery = graphql`
	query {
		aboutImage: file(
			relativePath: { regex: "/about--aaron-vince-image.png/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`
